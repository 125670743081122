// src/components/gtm.tsx
import { useEffect } from "react";
import { parseCookies } from "@wbly/common";

// src/js/init-gtm.js
var initGtm = (host, containerId, query) => {
  (function(w, d, s, l, i) {
    w[l] = w[l] || [];
    w[l].push({ "gtm.start": (/* @__PURE__ */ new Date()).getTime(), event: "gtm.js" });
    var f = d.getElementsByTagName(s)[0], j = d.createElement(s), dl = l != "dataLayer" ? "&l=" + l : "";
    j.async = true;
    j.src = `https://${host}/gtm.js?id=` + i + dl + (query ? `&${query}` : "");
    f.parentNode.insertBefore(j, f);
  })(window, document, "script", "dataLayer", `${containerId}`);
};

// src/parse-response.ts
var ResponseError = class extends Error {
  constructor(status, statusText, body) {
    super(`${status} ${statusText}`);
    this.status = status;
    this.statusText = statusText;
    this.body = body;
    this.name = "ResponseError";
  }
};
var parseResponse = async (response) => {
  if (!response.ok) {
    let body;
    if (response.headers.get("Content-Length") == "0") {
      body = null;
    } else if (response.headers.get("Content-Type") === "application/json") {
      body = await response.json();
    } else {
      body = await response.text();
    }
    throw new ResponseError(response.status, response.statusText, body);
  }
  return await response.json();
};

// src/set-ecommerce-model.ts
var setEcommerceModel = async (endpoint, orderNumber) => {
  let url = endpoint;
  if (orderNumber) {
    url = `${url}?order_number=${orderNumber}`;
  }
  const response = await fetch(url);
  const data = await parseResponse(response);
  window.ecommerce = data;
};

// src/report-error.ts
var reportEventError = async (endpoint, context) => {
  try {
    const response = await fetch(endpoint, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(context)
    });
    return await parseResponse(response);
  } catch (err) {
    if (err instanceof ResponseError) {
      console.error(
        "Failed to report event error",
        err.status,
        err.statusText,
        err.body
      );
    } else {
      console.error("Failed to report event error", err, context);
    }
  }
};

// src/components/gtm.tsx
var gtmDisabledCookieName = "disable_gtm";
var GTM = ({
  initialDataLayer,
  host,
  containerId,
  query,
  ecommerceEndpoint,
  reportErrorEndpoint,
  orderNumber,
  enabledFeatures
}) => {
  useEffect(() => {
    if (parseCookies(document.cookie)[gtmDisabledCookieName]) {
      return;
    }
    window.dataLayer = window.dataLayer || initialDataLayer || [];
    initGtm(host, containerId, query);
    setEcommerceModel(ecommerceEndpoint, orderNumber).catch((err) => {
      reportEventError(reportErrorEndpoint, {
        message: `Failed to load ecommerce model: ${err.message}`,
        name: err.name || "ecommerce-model-load-error",
        context: {
          body: err instanceof ResponseError ? err.body : null,
          page: window.location.href,
          orderNumber
        }
      });
    });
    if (enabledFeatures) {
      enabledFeatures.forEach((feature) => {
        window.dataLayer.push({
          event: "optimizely-decision-fs",
          "optimizely-flagKey": feature.featureKey,
          "optimizely-ruleKey": feature.ruleKey,
          "optimizely-variationKey": feature.variationKey
        });
      });
    }
  }, []);
  return null;
};

// src/components/gtm-no-script.tsx
import { jsx } from "react/jsx-runtime";
var GTMNoScript = ({ host, containerId, query }) => /* @__PURE__ */ jsx("noscript", { children: /* @__PURE__ */ jsx(
  "iframe",
  {
    src: `https://${host}/ns.html?id=${containerId}&${query}`,
    height: "0",
    width: "0",
    style: {
      display: "none",
      visibility: "hidden"
    }
  }
) });
export {
  GTM,
  GTMNoScript
};
